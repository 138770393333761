<template >
  <div name="evidenceadd">
    <transition name="infomodal">
      <div
        class="modal-mask fixed top-0 left-0 w-full h-auto outline-none fade"
      >
        <div
          class="
            modal-container
            w-11/12
            mt-5
            mb-10
            mx-auto
            rounded
            shadow-lg
            z-50
            h-screen
            bg-list-grey
          "
        >
          <header class="h-12 p-4 pt-10 pb-8 w-11/12 mx-auto">
            <i
              class="
                fas
                fa-times-circle
                cursor-pointer
                fixed
                right-0
                items-center
                mt-0
                rounded-2
                mr-40
                text-black text-4xl
                z-50
              "
              @click="$emit('close')"
              style="right: -12px"
            ></i>
            <h1 class="text-gray-700 font-bold text-4xl">Add new evidence</h1>
          </header>
          <div class="h-screen overflow-y-auto w-11/12 mx-auto mt-16">
            <slot name="body">
              <form
                v-on:submit.prevent="submitEvidence"
                v-if="!isEvidenceComplete"
              >
                <div class="boxer">
                  <div class="w-full px-3">
                    <label class="boxer-header" for="grid-outline">
                      Evidence record
                    </label>
                    <upload
                      :currentFile="fileUrl"
                      @fileuploaded="setFile"
                    ></upload>
                    <p class="text-red-300 text-xs italic pt-1">
                      Please fill out this field.
                    </p>
                  </div>
                </div>

                <div class="boxer">
                  <div class="w-full px-3">
                    <label class="boxer-header" for="grid-outline">
                      Evidence class / group
                    </label>
                    <ul class="mt-6 mb-1">
                      <li v-for="item in classes" class="inline-block mr-2">
                        <button
                          type="button"
                          @click="setClassName(item)"
                          class="
                            hover:bg-green-200 hover:text-white
                            font-bold
                            py-2
                            px-4
                            mb-2
                            rounded
                            icon
                          "
                          v-bind:class="
                            classButtonPressed(item)
                              ? 'bg-green-300 text-white'
                              : 'bg-gray-200 text-gray-500'
                          "
                        >
                          {{ item.name }}
                        </button>
                      </li>
                    </ul>
                    <p class="text-red-300 text-xs italic pt-1">
                      Please fill out this field.
                    </p>
                  </div>
                </div>

                <div class="boxer">
                  <div class="w-full px-3">
                    <label class="boxer-header" for="grid-outline">
                      Evidence lesson
                    </label>
                    <ul class="mt-6 mb-1">
                      <li v-for="item in lessons" class="inline-block mr-2">
                        <button
                          type="button"
                          @click="setLesson(item)"
                          class="
                            hover:bg-green-200 hover:text-white
                            font-bold
                            py-2
                            px-4
                            mb-2
                            rounded
                            icon
                          "
                          v-bind:class="
                            lessonButtonPressed(item)
                              ? 'bg-green-300 text-white'
                              : 'bg-gray-200 text-gray-500'
                          "
                        >
                          {{ item.name }}
                        </button>
                      </li>
                    </ul>
                    <p class="text-red-300 text-xs italic pt-1">
                      Please fill out this field.
                    </p>
                  </div>
                </div>

                <div class="boxer">
                  <div class="w-full px-3">
                    <label class="boxer-header" for="grid-outline">
                      Notes
                    </label>
                    <textarea
                      v-model="description"
                      rows="3"
                      class="p-1"
                    ></textarea>
                  </div>
                </div>

                <footer
                  class="h-24 border-t border-gray-400 border-solid mt-4 mb-48"
                >
                  <p
                    v-if="validationInError"
                    class="bg-red-400 rounded w-11/12 text-white p-4 mt-2"
                  >
                    <i class="fas fa-exclamation-circle pr-2 text-xl"></i>All
                    required fields must be complete to upload evidence
                  </p>
                  <button
                    type="submit"
                    :disabled="isDisabled"
                    class="
                      bg-blue-400
                      hover:bg-blue-500
                      text-white
                      py-2
                      px-4
                      rounded
                      disabled:opacity-50 disabled:cursor-not-allowed
                      mt-6
                      w-40
                    "
                  >
                    Upload Evidence
                  </button>
                </footer>
              </form>

              <template v-if="isEvidenceComplete">
                <div class="boxer">
                  <div class="w-full px-3">
                    <label class="boxer-header" for="grid-outline">
                      Add pupils
                    </label>
                    <div class="h-1/2 pb-4 max-h-screen overflow-y-scroll">
                      <h5 class="mb-6 ml-2 font-semibold text-orange-500">
                        {{ pupilsSelected.length }} pupils selected
                      </h5>

                      <div
                        class="flex flex-wrap opacity-100"
                        v-if="pupilsLoaded"
                      >
                        <div
                          v-for="pupil in orderedPupils"
                          @click="selectPupil(pupil)"
                          class="
                            relative
                            text-center
                            block
                            mx-2
                            mb-4
                            p-2
                            w-32
                            cursor-pointer
                            hover:opacity-75
                          "
                        >
                          <div
                            class="
                              absolute
                              right-0
                              mr-6
                              top-0
                              mt-1
                              text-white
                              p-1
                              rounded-full
                              h-8
                              w-8
                            "
                            v-bind:class="selectedPupilStyle(pupil)"
                          >
                            <i class="fas fa-check text-lg pt-1"></i>
                          </div>
                          <div
                            class="
                              mx-auto
                              rounded-full
                              h-16
                              w-16
                              mb-2
                              bg-gray-500
                            "
                          >
                            <i
                              class="fas fa-user text-3xl text-gray-200 pt-4"
                            ></i>
                          </div>
                          <div class="text-xs font-semibold">
                            {{ pupil.name }}
                          </div>
                        </div>
                      </div>
                      <p class="m-5 mx-12" v-else>
                        We could not find any data for your given selection.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="boxer">
                  <div class="w-full px-3">
                    <label class="boxer-header" for="grid-outline">
                      Add outcomes
                    </label>
                    <div class="h-1/2 pb-4 max-h-screen overflow-y-scroll">
                      <h5 class="mb-6 ml-2 font-semibold text-orange-500">
                        {{ outcomesSelected.length }} outcomes selected
                      </h5>

                      <table
                        v-if="outcomesLoaded"
                        class="
                          table-wrapper
                          w-full
                          bg-white
                          mx-auto
                          rounded
                          shadow-sm
                        "
                      >
                        <tbody v-for="section in outcomeSections">
                          <tr>
                            <td colspan="2" class="text-sm uppercase font-bold">
                              {{ section.value }}
                            </td>
                          </tr>
                          <tr
                            v-for="(item, index) in outcomesOrderedBySection(
                              section.key
                            )"
                          >
                            <td width="90%">
                              <span
                                class="
                                  txt-xs
                                  italic
                                  text-gray-500
                                  mr-2
                                  inline-block
                                  float-left
                                  h-10
                                "
                                >{{ index + 1 }})</span
                              >
                              <span class="inline"> {{ item.title }}</span>
                            </td>
                            <td class="text-right">
                              <div
                                @click="selectOutcome(item)"
                                class="
                                  text-white
                                  p-1
                                  text-center
                                  rounded-full
                                  h-8
                                  w-8
                                  cursor-pointer
                                  hover:opacity-75
                                "
                                v-bind:class="selectedOutcomeStyle(item)"
                              >
                                <i class="fas fa-check text-base pt-1"></i>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <p class="m-5 mx-12" v-else>
                        We could not find any data for your given selection.
                      </p>
                    </div>
                  </div>
                </div>

                <footer
                  class="h-24 border-t border-gray-400 border-solid mt-4 mb-48"
                >
                  <p
                    v-if="validationInError"
                    class="bg-red-400 rounded w-11/12 text-white p-4 mt-2"
                  >
                    <i class="fas fa-exclamation-circle pr-2 text-xl"></i>All
                    required fields must be complete to upload evidence
                  </p>
                  <button
                    @click="$emit('close')"
                    class="
                      bg-blue-400
                      hover:bg-blue-500
                      text-white
                      py-2
                      px-4
                      rounded
                      mt-6
                      w-40
                    "
                  >
                    Back to evidences
                  </button>
                </footer>
              </template>
            </slot>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

  <script>
import { mapGetters } from "vuex";
import { EvidenceService } from "@/services/evidence.service";
import { PupilService } from "@/services/pupil.service";
import { OutcomeService } from "@/services/outcome.service";

const upload = () => import("@/components/EvidenceUpload.vue");

export default {
  name: "evidenceadd",
  props: {
    classes: { type: Array, required: true },
    lessons: { type: Array, required: true },
    pupils: { type: Array, required: true },
    classPreset: { type: Object, required: false },
    lessonPreset: { type: Object, required: true },
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      currentAccess: "currentAccess",
      settings: "settings",
    }),
    isDisabled() {
      if (this.lessonSelected === undefined || this.fileUrl.length === 0) {
        return true;
      } else {
        return false;
      }
    },
    outcomesLoaded() {
      return this.outcomes.length > 0;
    },
    outcomesOrdered() {
      return this.$orderBy(this.outcomes, ["order", "title"]);
    },
    outcomeSections() {
      let criteriaList = this.outcomesOrdered.map((o) => o.criteria);
      let distinct = [...new Set(criteriaList)];

      if (distinct.length === 0) {
        return { key: distinct, value: distinct.uppercase() };
      } else {
        return this.settings.assessmentCriteria.filter((a) =>
          distinct.includes(a.key)
        );
      }
    },
    pupilsLoaded() {
      return this.pupils.length > 0;
    },
    orderedPupils() {
      var vm = this;
      var classChosen = vm.classSelected;

      if (classChosen == undefined) {
        return this.$orderBy(this.pupils, ["yeargroup", "class", "name"]);
      } else {
        var filtered = vm.pupils.filter(function (pupil) {
          let filtered = true;

          if (filtered) {
            if (classChosen.id > 0) {
              filtered = pupil.class === classChosen.name;
            }
          }
          return filtered;
        });

        return this.$orderBy(filtered, ["yeargroup", "class", "name"]);
      }
    },
  },
  data() {
    return {
      errored: false,
      lessonSelected: this.lessonPreset,
      classSelected: this.classPreset.id === 0 ? null : this.classPreset,
      description: "",
      fileUrl: "",
      validationInError: false,
      pupilsSelected: [],
      isAllSelected: false,
      isEvidenceComplete: false,
      outcomes: [],
      outcomesSelected: [],
      evidence: null,
    };
  },
  created() {
    this.fetchOutcomes();
  },
  methods: {
    fetchOutcomes() {
      console.log(this.settings.display.outcomes);
      OutcomeService.getByCourse(
        this.lessonSelected.course_id,
        this.settings.display.outcomes
      )
        .then((response) => (this.outcomes = response.data))
        .catch((err) => {
          console.log("Outcomes " + err);
          this.errored = true;
        });
    },
    outcomesOrderedBySection(section) {
      return this.outcomesOrdered.filter((o) => o.criteria === section);
    },
    lessonButtonPressed(object) {
      return this.lessonSelected == null
        ? false
        : this.lessonSelected.id == object.id;
    },
    classButtonPressed(object) {
      return this.classSelected == null
        ? false
        : this.classSelected.id == object.id;
    },
    setClassName(item) {
      if (this.classSelected == null || this.classSelected.id != item.id) {
        this.classSelected = item;
      } else {
        this.classSelected = null;
      }
    },
    setLesson(item) {
      if (this.lessonSelected == null || this.lessonSelected.id != item.id) {
        this.lessonSelected = item;
      } else {
        this.lessonSelected = null;
      }
    },
    setFile(value) {
      this.fileUrl = value.file;
      this.type = value.type;
    },
    processEvidence() {
      const className =
        this.classSelected == undefined ? "" : this.classSelected.name;

      console.log(this.fileUrl["type"]);

      let payload = {
        title: "Evidence " + this.lessonSelected.name + " " + className,
        media_type: this.type,
        media_url: this.fileUrl,
        notes: { String: this.description, Valid: true },
        lesson_id: this.lessonSelected.id,
        class: className,
      };

      EvidenceService.add(payload)
        .then((response) => this.completion(response.data))
        .catch((err) => {
          console.log(err);
        });
    },
    completion(response) {
      this.evidence = response;
      if (this.evidence != undefined) {
        this.isEvidenceComplete = true;
        window.scrollTo(0, 0);
      }
    },
    submitEvidence() {
      if (this.fileUrl === "" || this.lessonSelected === undefined) {
        this.validationInError = true;
      } else {
        this.processEvidence();
      }
    },
    selectedPupilStyle(pupil) {
      let found = this.pupilsSelected.find(
        (p) => p.identifier === pupil.identifier
      );
      return found ? "bg-green-500" : "bg-gray-300";
    },
    selectPupil(pupil) {
      const vm = this;
      if (this.pupilsSelected.some((p) => p.identifier === pupil.identifier)) {
        EvidenceService.deletePupil(vm.evidence, pupil)
          .then((response) => {
            vm.pupilsSelected.splice(
              vm.pupilsSelected.findIndex(
                (item) => item.identifier === pupil.identifier
              ),
              1
            );
          })
          .catch((err) => {
            vm.failure(err);
          });
      } else {
        let payload = {
          evidence_identifier: vm.evidence.identifier,
          register_identifier: pupil.identifier,
        };
        EvidenceService.addPupil(payload)
          .then((response) => {
            vm.pupilsSelected.push(pupil);
          })
          .catch((err) => {
            vm.failure(err);
          });
      }
    },
    selectOutcome(outcome) {
      const vm = this;
      if (
        vm.outcomesSelected.some((o) => o.identifier === outcome.identifier)
      ) {
        EvidenceService.deleteOutcome(vm.evidence, outcome)
          .then((response) => {
            vm.outcomesSelected.splice(
              vm.outcomesSelected.findIndex(
                (item) => item.identifier === outcome.identifier
              ),
              1
            );
          })
          .catch((err) => {
            vm.failure(err);
          });
      } else {
        let payload = {
          evidence_identifier: vm.evidence.identifier,
          outcome_identifier: outcome.identifier,
        };
        EvidenceService.addOutcome(payload)
          .then((response) => {
            vm.outcomesSelected.push(outcome);
          })
          .catch((err) => {
            vm.failure(err);
          });
      }
    },
    selectedOutcomeStyle(outcome) {
      let found = this.outcomesSelected.find(
        (o) => o.identifier === outcome.identifier
      );
      return found ? "bg-green-500" : "bg-gray-300";
    },
    failure(error) {
      console.log(error);
    },
  },
  components: {
    upload,
  },
};
</script>

<style scoped>
.section-head {
  @apply text-xl m-5 ml-0 font-bold text-orange-500;
}

.boxer {
  @apply mb-8 bg-gray-100 py-3 rounded border border-gray-300;
}

.boxer-header {
  @apply block uppercase tracking-wide text-blue-700 text-sm font-bold mb-3;
}
</style>